<template>
    <div>
        <v-app>
            <v-card>
                <v-card-title>
                    Ordenes archivados 
                    <v-spacer></v-spacer> 
                    <v-btn class @click="fetchData" text icon color="blue">
                        <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-card outlined tile elevation="1">
                        <v-card-text style="padding-bottom: 0px;">
                            <div class="d-flex justify-space-between mb-4">
                                <v-text-field
                                v-model="search"
                                clearable
                                label="Buscar"
                                hide-details
                                dense
                                solo
                                class="mr-2"
                                ></v-text-field>
                                <v-btn class="btn-starkoms-primary" dark style="min-width: 138px;" @click="fetchData" >Buscar</v-btn>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-card-text>
                <v-card-text>
                    <v-data-table
                        :headers="headers"
                        :items="tableData"
                        v-model="selected"
                        no-results-text="No hay resultados disponibles"
                        no-data-text="No hay datos disponibles"
                        loading-text="Cargando datos..."
                        :sort-by="'id'"
                        :sort-desc="true"
                        class="elevation-1 datatable"
                        :loading="dataTableLoading"
                        :search="search"
                    >
                        <template v-slot:item.order_reference="{ item }">
                            <td>
                                <a class="text-decoration-none" href="#" @click="$router.push({name: 'orden archivada', params: {orden: item.id}})">{{item.order_reference}}</a>
                            </td>
                        </template>
                        <template v-slot:item.name_order_state="{ item }">
                            <td class="d-flex flex-column">
                                <button
                                :class="'btn btn-sm btn-'+item.colour_order_state"
                                v-text="item.name_order_state"
                                ></button>
                            </td>
                        </template>
                        <template v-slot:item.shipping_link="{ item }">
                            <td class="text-center">
                                <a :href="'#'" @click="getShippingLink(item)" >{{ item.shipping_number }}</a>
                            </td>
                        </template>
                        <template v-slot:item.created_at="{ item }">
                            <td
                                class="text-center"
                            >{{$moment(item.created_at).format('LLLL')}}</td>
                        </template>
                        <template v-slot:item.acciones="{ item }">
                            <td class="d-flex">
                                <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <i
                                    style="font-size: 1.5rem !important;"
                                    class="icon-action_editorder mr-2"
                                    v-on="on"
                                    @click="$router.push({name: 'orden archivada', params: {orden: item.id}})"
                                    ></i>
                                </template>
                                <span>Ver/Editar orden</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-icon
                                    style="font-size: 1.5rem !important"
                                    small
                                    color="primary"
                                    class="mr-2"
                                    v-on="on"
                                    @click="restore(item)"
                                    >mdi mdi-file-restore</v-icon>
                                </template>
                                <span>Restaurar</span>
                                </v-tooltip>
                            </td>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-app>
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
export default {
    data(){
        return {
            tableData: [],
            dataTableLoading: false,
            search: "",
            selected: [],
        }
    },
    mounted(){
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Ordenes archivadas", route: "/#/ordenes-archivadas" },
        ]);
        this.fetchData();
    },
    methods:{
        fetchData(){
            var vm = this;
            this.axios({
                url: "orders/order/archived",
                method: "GET",
            }).then( response => {
                vm.tableData = response.data;
            }).catch( error => {
                console.log(error);
            })
        },
        getShippingLink(item)
        {
            this.axios({
                url: 'shipment/shipping_link?order_reference='+item.order_reference+'&store='+item.store_id+'&source='+item.source_id,
            }).then( response => {
                window.open(response.data, '_blank');
            })
        },
        restore(item){
            if (confirm("Está seguro?")) {
                var vm = this;
                this.axios({
                    url: "orders/order/" + item.id + "/archived",
                    method: "DELETE",
                })
                .then((response) => {
                    vm.abrirMensajeSuccess("Pedido restaurado correctamente");
                })
                .catch((error) => {
                    console.log(error);
                });
            }
        },
        abrirMensajeSuccess(mensaje) {
            this.$bvToast.toast(mensaje, {
                title: `Información`,
                variant: "success",
                solid: true,
                toaster: "b-toaster-bottom-center",
            });
            this.fetchData();
        },
    },
    computed: {
        headers() {
            return [
                { text: "# de orden", align: "left", sortable: false, value: "id", width: '100px' },
                { text: "Referencia", value: "order_reference" /*, filter: v => (v+'').toLowerCase().includes(this['search'].toLowerCase())*/,},
                { text: "Tipo", value: "order_type_code"},
                { text: "Email", value: "email" },
                { text: "Origen", value: "source" },
                { text: "Tracking", value: "shipping_link"},
                { text: "Fecha", value: "created_at"},
                { text: "Estado", value: "name_order_state"},
                { text: "Acciones", value: "acciones", sortable: false },
            ];
        },
    },
}
</script>