var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app',[_c('v-card',[_c('v-card-title',[_vm._v(" Ordenes archivados "),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","icon":"","color":"blue"},on:{"click":_vm.fetchData}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1),_c('v-card-text',[_c('v-card',{attrs:{"outlined":"","tile":"","elevation":"1"}},[_c('v-card-text',{staticStyle:{"padding-bottom":"0px"}},[_c('div',{staticClass:"d-flex justify-space-between mb-4"},[_c('v-text-field',{staticClass:"mr-2",attrs:{"clearable":"","label":"Buscar","hide-details":"","dense":"","solo":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"btn-starkoms-primary",staticStyle:{"min-width":"138px"},attrs:{"dark":""},on:{"click":_vm.fetchData}},[_vm._v("Buscar")])],1)])],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1 datatable",attrs:{"headers":_vm.headers,"items":_vm.tableData,"no-results-text":"No hay resultados disponibles","no-data-text":"No hay datos disponibles","loading-text":"Cargando datos...","sort-by":'id',"sort-desc":true,"loading":_vm.dataTableLoading,"search":_vm.search},scopedSlots:_vm._u([{key:"item.order_reference",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('a',{staticClass:"text-decoration-none",attrs:{"href":"#"},on:{"click":function($event){return _vm.$router.push({name: 'orden archivada', params: {orden: item.id}})}}},[_vm._v(_vm._s(item.order_reference))])])]}},{key:"item.name_order_state",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"d-flex flex-column"},[_c('button',{class:'btn btn-sm btn-'+item.colour_order_state,domProps:{"textContent":_vm._s(item.name_order_state)}})])]}},{key:"item.shipping_link",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_c('a',{attrs:{"href":'#'},on:{"click":function($event){return _vm.getShippingLink(item)}}},[_vm._v(_vm._s(item.shipping_number))])])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$moment(item.created_at).format('LLLL')))])]}},{key:"item.acciones",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('i',_vm._g({staticClass:"icon-action_editorder mr-2",staticStyle:{"font-size":"1.5rem !important"},on:{"click":function($event){return _vm.$router.push({name: 'orden archivada', params: {orden: item.id}})}}},on))]}}],null,true)},[_c('span',[_vm._v("Ver/Editar orden")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",staticStyle:{"font-size":"1.5rem !important"},attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.restore(item)}}},on),[_vm._v("mdi mdi-file-restore")])]}}],null,true)},[_c('span',[_vm._v("Restaurar")])])],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }